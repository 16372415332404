<template>
  <div class="header">
    <div
      class="guidelineTitle mb-2 is-flex is-flex-grow-4 is-justify-content-space-between is-align-items-top"
    >
      <div class="is-flex is-flex-direction-column">
        <div class="mb-2 is-flex">
          {{
            selectedGuideline !== null
              ? `${selectedGuideline.guidelineTitle || ''}${
                  selectedGuideline.product
                    ? ` (${selectedGuideline.product})`
                    : ''
                }`
              : ''
          }}
        </div>
        <div
          v-if="displayIndicationStatus"
          :class="[
            'indicationMessage',
            conditionsMet,
            'is-flex is-align-items-center',
          ]"
          data-cy="indicationStatusMessage"
        >
          <template v-if="conditionsMet === 'not-covered'">
            <b-icon icon="circle-xmark" /> Not Covered
          </template>
          <template v-else-if="conditionsMet === 'met'">
            <b-icon icon="circle-check" /> Indications for Admission Met
            <template v-if="glos">GLOS {{ glos }} </template>
          </template>
          <template v-else-if="conditionsMet === 'maybe-met'">
            <b-icon icon="circle-minus" /> Indications for Admission May Be Met
          </template>
          <template v-else>
            <b-icon icon="circle-xmark" /> Indications for Admission Not Met
          </template>
        </div>
      </div>
      <div v-if="searchData.length > 1">
        <button
          id="btnNext"
          class="button submit is-primary is-flex is-jusify-content-space-between"
          data-cy="nextButton"
          @click="nextStep"
        >
          <span data-cy="nextText">{{ nextText }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { NoGuidelinesApplyConstants } from '@utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'GuidelineTitle',

  computed: {
    ...mapGetters('guidelineSearch', ['selectedGuideline', 'searchData']),
    ...mapGetters('clientConfig', ['clientConfig']),
    ...mapGetters('guidelineData', ['guideline', 'notCovered']),
    ...mapGetters('documentGuideline', ['overallStatus', 'hasDocumentation']),
    conditionsMet() {
      if (this.notCovered) return 'not-covered'
      const guideline = this.selectedGuideline
      const overallStatus = this.overallStatus.find(function (s) {
        return (
          s.code === guideline.associatedCode &&
          s.guidelineHsim === guideline.hsim
        )
      })
      if (overallStatus?.status) return 'met'
      if (this.hasMatchingIndications) return 'maybe-met'
      return 'not-met'
    },
    displayIndicationStatus() {
      return (
        this.clientConfig.displayIndicationStatus &&
        this.selectedGuideline.hsim !== NoGuidelinesApplyConstants.Hsim
      )
    },
    glos() {
      return this.selectedGuideline.lengthOfStay
    },
    hasMatchingIndications() {
      return this.guideline?.matchedIndications?.length > 0
    },
    allCodesHaveDocumentation() {
      const notDocumented = this.searchData.filter((row) => {
        return (
          row.searchOutput.searchResults.length > 0 &&
          !this.hasDocumentation(row)
        )
      })

      return notDocumented.length === 0
    },
    nextText() {
      if (this.allCodesHaveDocumentation) {
        return 'Preview'
      }
      return 'Document Next Code'
    },
  },
  methods: {
    nextStep() {
      // navigate back to Guideline List tab
      this.$emit('next-step')
    },
  },
}
</script>

<style lang="scss">
@import '@design';
.header {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;

  .guidelineTitle {
    font-size: 1.4rem;
  }

  .indicationMessage {
    font-size: 1.25rem;
    color: $highlight-color;
  }
}
</style>
