var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bounded-scroll",attrs:{"id":"guidelineList"}},[(_vm.getSearchResultLength > 0)?_vm._l((_vm.searchResults),function(item){return _c('div',{key:item.searchOutput.guidelineCode,attrs:{"id":"codeList"}},[(_vm.isKeywordSearch(item))?[(item.searchOutput.guidelineCode)?_c('div',{staticClass:"greyBackground"},[_c('div',{staticClass:"codeContent"},[_c('span',{attrs:{"id":"code"}},[_vm._v(" "+_vm._s(item.searchOutput.guidelineCode)+" "+_vm._s(item.searchOutput.guidelineCodeDescription ? '-' : '')+" ")]),_c('span',{attrs:{"id":"codeDescription"}},[_vm._v(" "+_vm._s(item.searchOutput.guidelineCodeDescription)+" ")])]),(_vm.hasDocumentation(item))?_c('div',{staticClass:"checkboxContent"},[_c('span',{staticClass:"checkboxDocumented circle",attrs:{"type":"is-success"}},[_c('b-icon',{attrs:{"icon":"check"}})],1)]):_vm._e()]):_vm._e()]:_vm._e(),(item.searchOutput.searchResults)?[(item.searchOutput.searchResults.length > 0)?_c('b-table',{attrs:{"paginated":true,"per-page":20,"narrowed":"","striped":"","hoverable":"","mobile-cards":false,"show-header":false,"data":item.searchOutput.searchResults,"selected":_vm.selectedGuidelineRow},on:{"update:selected":function($event){_vm.selectedGuidelineRow=$event},"click":function (row) { return _vm.confirmClearGuideline(row, item.searchOutput.guidelineCode); }}},[_c('b-table-column',{attrs:{"cell-class":"guidelineTitle","field":"title","label":"Guideline Title"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',[_c('a',{class:_vm.guidelineDocumented(
                    props.row.hsim,
                    item.searchOutput.guidelineCode
                  )
                    ? 'documentedGuidelineTitle'
                    : ''},[_vm._v(" "+_vm._s(props.row.guidelineTitle)+" "+_vm._s(props.row.product ? (" (" + (props.row.product) + ")") : '')+" ")])])]}}],null,true)}),_c('b-table-column',{attrs:{"title":"disclaimer","label":"disclaimer","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
                _vm.guidelineDocumented(
                  props.row.hsim,
                  item.searchOutput.guidelineCode
                )
              )?_c('span',{class:_vm.guidelineDocumentedRowStyle(
                  props.row.hsim,
                  item.searchOutput.guidelineCode
                )
                  ? 'documentedGuidelineIcon whiteColor'
                  : 'documentedGuidelineIcon'},[_c('b-icon',{attrs:{"icon":"file-lines"}})],1):_vm._e(),(props.row.customGuideline)?_c('b-icon',{staticClass:"disclaimer",attrs:{"title":_vm.CustomGuidelineDisclaimerText,"icon":"m"}}):_vm._e()]}}],null,true)})],1):_c('b-message',{attrs:{"title":"No Guidelines Found","type":"is-info","closable":false,"has-icon":"","icon":"circle-info"}},[_vm._v(" "+_vm._s(_vm.NoGuidelineFoundErrorText)+" ")])]:_vm._e()],2)}):(
      _vm.searchData && (_vm.guidelineSearchErrorCode || _vm.guidelineSearchError)
    )?[_c('b-message',{staticClass:"tag-message-danger",attrs:{"title":_vm.guidelineListErrorHeader(),"type":"is-danger","closable":false,"has-icon":"","icon":"circle-exclamation"}},_vm._l((_vm.guidelineListErrorMessage()),function(line){return _c('div',{key:line},[_c('span',{staticClass:"new-line"},[_vm._v(_vm._s(line))])])}),0)]:(_vm.getSearchResultLength === 0)?_c('div',{staticClass:"tag-message-info"},[_c('b-message',{attrs:{"title":"No Guidelines Found","type":"is-info","closable":false,"has-icon":"","icon":"circle-info"}},[_vm._v(" "+_vm._s(_vm.NoGuidelineFoundErrorText)+" ")])],1):_c('div',{staticClass:"spinner"},[_c('RotateSquare2')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }