<script>
import { postErrorToWindow } from '@src/postErrorToWindow'
import { mapActions, mapGetters } from 'vuex'
import { errorRedirectMixin } from '@mixins/error-redirect'
import RotateSquare2 from '@components/rotate-square2.vue'
import {
  NoGuidelinesApplyConstants,
  CustomGuidelineDisclaimer,
} from '@utils/constants'
import ErrorMessages from '@src/errorMessages'

export default {
  components: {
    RotateSquare2,
  },
  mixins: [errorRedirectMixin],
  data() {
    return { selectedGuidelineRow: {} }
  },
  computed: {
    ...mapGetters('guidelineSearch', [
      'searchData',
      'guidelineSearchError',
      'guidelineSearchErrorCode',
    ]),
    ...mapGetters('documentGuideline', [
      'previewDocumentedGuidelines',
      'hasDocumentation',
      'hasSummary',
      'documentedGuidelines',
    ]),
    ...mapGetters('xsrfToken', ['xsrfToken']),
    searchResults() {
      return this.searchData
    },
    getSearchResultLength() {
      return this.searchData?.length
    },
    NoGuidelineFoundErrorText() {
      return ErrorMessages.Backend.NoGuidelineFound
    },
    CustomGuidelineDisclaimerText() {
      return CustomGuidelineDisclaimer
    },
  },
  watch: {
    searchResults: function () {
      if (
        this.searchResults?.length === 1 &&
        this.searchResults[0]?.searchOutput?.searchResults?.length === 1
      ) {
        this.select(this.searchResults[0].searchOutput.searchResults[0])
      } else if (this.searchResults?.length === 0) {
        postErrorToWindow(
          this.NoGuidelineFoundErrorText,
          this.$route.query.refererUrl,
          this.$route.query.notificationMode
        )
      }
    },
    guidelineSearchError: function () {
      if (this.guidelineSearchError) {
        this.validateError(
          this.guidelineSearchErrorCode,
          this.guidelineSearchError
        )
      }
    },
    xsrfToken: {
      handler(xsrfToken) {
        if (xsrfToken !== null) {
          this.searchGuidelines()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('guidelineSearch', [
      'search',
      'selectGuideline',
      'launchSession',
    ]),
    ...mapActions('documentGuideline', ['removeAllDocumentedByCode']),
    ...mapActions('guidelineData', [
      'resetGuidelineData',
      'clearRequestInterrupt',
      'setReady',
    ]),
    async confirmClearGuideline(row, associatedCode) {
      const documentedGuideline =
        this.documentedGuidelines &&
        this.documentedGuidelines?.find((g) => g.code === associatedCode)

      if (
        !documentedGuideline ||
        (!this.hasSummary(documentedGuideline) &&
          documentedGuideline.guidelineHsim !==
            NoGuidelinesApplyConstants.Hsim) ||
        documentedGuideline.guidelineHsim.includes(row.hsim)
      ) {
        await this.select(row, associatedCode)
        return
      }

      if (
        documentedGuideline.guidelineHsim === NoGuidelinesApplyConstants.Hsim
      ) {
        this.removeAllDocumentedByCode(associatedCode)
        this.select(row, associatedCode)
      } else {
        this.$buefy.dialog.confirm({
          title: 'Clear Existing Documentation',
          message: `Selecting a different guideline will clear existing documentation.`,
          cancelText: 'Cancel',
          confirmText: 'Continue',
          type: 'is-danger',
          hasIcon: true,
          icon: 'triangle-exclamation',
          onConfirm: async () => {
            this.removeAllDocumentedByCode(associatedCode)
            await this.select(row, associatedCode)
          },
          onCancel: () => {
            this.selectRow(documentedGuideline)
          },
        })
      }
    },
    async select(row, associatedCode) {
      this.setReady(false)
      row.associatedCode = associatedCode
      this.selectedGuidelineRow = row
      this.clearRequestInterrupt(row.hsim)
      await this.selectGuideline({ selectedGuideline: row })
      this.$emit('guideline-select')
    },
    guidelineListErrorHeader() {
      if (this.searchData && this.guidelineSearchErrorCode) {
        return this.guidelineSearchErrorCode
      }

      return null
    },
    guidelineListErrorMessage() {
      if (this.guidelineSearchError) {
        return (
          this.guidelineSearchError?.Detail?.split('\\n') ??
          this.guidelineSearchError?.split('\\n')
        )
      }
      return null
    },
    isKeywordSearch(searchItem) {
      return (
        searchItem?.rulesOutput?.searchType.toLowerCase().trim() !== 'keyword'
      )
    },
    removeAllDocumentions(code) {
      this.$nextTick(() => {
        this.removeAllDocumentedByCode(code)
        this.resetGuidelineData()
      })
    },
    selectRow(guideline) {
      if (!guideline) {
        return
      }

      const result = this.searchResults.find(
        (row) => row.searchOutput.guidelineCode === guideline.code
      )
      const currentGuideline = result?.searchOutput.searchResults.find((row) =>
        guideline.guidelineHsim.includes(row.hsim)
      )

      if (currentGuideline) {
        this.selectedGuidelineRow = currentGuideline
      }
    },
    guidelineDocumented(hsim, guidelineCode) {
      const documented =
        this.previewDocumentedGuidelines &&
        this.previewDocumentedGuidelines?.find(
          (g) => g.guidelineHsim.includes(hsim) && g.code === guidelineCode
        )
      return (
        documented?.allIndicationPaths.length > 0 ||
        documented?.guidelineHsim === NoGuidelinesApplyConstants.Hsim
      )
    },
    guidelineDocumentedRowStyle(hsim, guidelineCode) {
      return (
        this.selectedGuidelineRow?.hsim === hsim &&
        this.selectedGuidelineRow?.associatedCode === guidelineCode
      )
    },
    searchGuidelines() {
      const endPointName = this.$route.query.endpoint
      if (endPointName === 'clinicalData') {
        this.launchSession({ sessionId: this.$route.query.sessionId })
      } else {
        this.search({
          sessionId: this.$route.query.sessionId,
          nonce: this.$route.query.nonce,
        })
      }
    },
  },
}
</script>

<template>
  <div id="guidelineList" class="bounded-scroll">
    <template v-if="getSearchResultLength > 0">
      <div
        v-for="item in searchResults"
        id="codeList"
        :key="item.searchOutput.guidelineCode"
      >
        <template v-if="isKeywordSearch(item)">
          <div v-if="item.searchOutput.guidelineCode" class="greyBackground">
            <div class="codeContent">
              <span id="code">
                {{ item.searchOutput.guidelineCode }}
                {{ item.searchOutput.guidelineCodeDescription ? '-' : '' }}
              </span>

              <span id="codeDescription">
                {{ item.searchOutput.guidelineCodeDescription }}
              </span>
            </div>

            <div v-if="hasDocumentation(item)" class="checkboxContent">
              <span type="is-success" class="checkboxDocumented circle">
                <b-icon icon="check"></b-icon>
              </span>
            </div>
          </div>
        </template>

        <template v-if="item.searchOutput.searchResults">
          <b-table
            v-if="item.searchOutput.searchResults.length > 0"
            :paginated="true"
            :per-page="20"
            narrowed
            striped
            hoverable
            :mobile-cards="false"
            :show-header="false"
            :data="item.searchOutput.searchResults"
            :selected.sync="selectedGuidelineRow"
            @click="
              (row) =>
                confirmClearGuideline(row, item.searchOutput.guidelineCode)
            "
          >
            <b-table-column
              v-slot="props"
              cell-class="guidelineTitle"
              field="title"
              label="Guideline Title"
            >
              <div>
                <a
                  :class="
                    guidelineDocumented(
                      props.row.hsim,
                      item.searchOutput.guidelineCode
                    )
                      ? 'documentedGuidelineTitle'
                      : ''
                  "
                >
                  {{ props.row.guidelineTitle }}
                  {{ props.row.product ? ` (${props.row.product})` : '' }}
                </a>
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              title="disclaimer"
              label="disclaimer"
              centered
            >
              <span
                v-if="
                  guidelineDocumented(
                    props.row.hsim,
                    item.searchOutput.guidelineCode
                  )
                "
                :class="
                  guidelineDocumentedRowStyle(
                    props.row.hsim,
                    item.searchOutput.guidelineCode
                  )
                    ? 'documentedGuidelineIcon whiteColor'
                    : 'documentedGuidelineIcon'
                "
              >
                <b-icon icon="file-lines"></b-icon>
              </span>
              <b-icon
                v-if="props.row.customGuideline"
                :title="CustomGuidelineDisclaimerText"
                class="disclaimer"
                icon="m"
              />
            </b-table-column>
          </b-table>

          <b-message
            v-else
            title="No Guidelines Found"
            type="is-info"
            :closable="false"
            has-icon
            icon="circle-info"
          >
            {{ NoGuidelineFoundErrorText }}
          </b-message>
        </template>
      </div>
    </template>

    <template
      v-else-if="
        searchData && (guidelineSearchErrorCode || guidelineSearchError)
      "
    >
      <b-message
        :title="guidelineListErrorHeader()"
        type="is-danger"
        :closable="false"
        has-icon
        icon="circle-exclamation"
        class="tag-message-danger"
      >
        <div v-for="line in guidelineListErrorMessage()" :key="line">
          <span class="new-line">{{ line }}</span>
        </div>
      </b-message>
    </template>

    <div v-else-if="getSearchResultLength === 0" class="tag-message-info">
      <b-message
        title="No Guidelines Found"
        type="is-info"
        :closable="false"
        has-icon
        icon="circle-info"
      >
        {{ NoGuidelineFoundErrorText }}
      </b-message>
    </div>

    <div v-else class="spinner">
      <RotateSquare2 />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
#guidelineList {
  tr:not(.is-selected) a {
    color: $color-text;
    text-decoration: underline;
  }

  tr:not(.is-selected) .disclaimer {
    color: white;
    background-color: #025893;
  }
}

.node-selected {
  color: $white;
  background-color: $highlight-color;
}
</style>

<style lang="scss">
#guidelineList {
  tr {
    cursor: pointer;
  }
}

#codeList {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin-bottom: 12px;
  overflow: hidden;
  border: solid 1px #dee2e6;
  border-radius: 3px;
}

.greyBorder {
  border: solid 1px #dee2e6;
  border-radius: 3px;
}
.greyBackground {
  padding: 10px;
  background-color: #f4f5f5;
}
#codeDescription {
  font-size: 13px;
}
#code {
  font-size: 15px;
  font-weight: 900;
}
.checkboxDocumented {
  position: relative;
  float: right;
  font-size: 15px;
  color: white;
}
.circle {
  display: inline-block;
  background-color: #025893;
  border-radius: 60px;
  box-shadow: 0 0 10px #888;
}
.codeContent {
  float: left;
  width: 95%;
  clear: none;
}
.checkboxContent {
  float: left;
  width: 5%;
  clear: none;
}
.modal-card {
  border: 3px solid gray;
}
.documentedGuidelineIcon {
  float: right;
  margin-top: -2px;
  margin-bottom: -6px;
  font-size: 20px;
  color: #364767;
}
.documentedGuidelineTitle {
  font-weight: bolder;
}
.whiteColor {
  color: white;
}

.disclaimer {
  float: right;
  margin-top: -2px;
  margin-bottom: -6px;
  font-size: 20px;
  color: #025893;
  background-color: white;
}
.tag-message-info {
  border: 1px solid #025893;
  border-radius: 4px;
}
.tag-message-danger {
  background: #f7eaea;
  border: 1px solid #dc3200;
  border-radius: 4px;
}
.new-line {
  color: #444;
  white-space: pre-line;
}
</style>
